
import './App.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import Home from './pages/Home';
import Games from './pages/Games';
import Shop from './pages/Shop';
import About from './pages/About';

function App() {
  return (
      <Router>
        <div className="App">
          <header className='App-header'>
            <Navbar />
            <Routes>
              <Route exact path="/" element={<Home />}/>
              <Route exact path="/games" element={<Games />}/>
              <Route exact path="/shop" element={<Shop />}/>
              <Route exact path="/about" element={<About />}/>
            </Routes>
            <Footer />
          </header>
      </div>
    </Router>
  );
}

export default App;
