import { Link } from 'react-router-dom';
import logoText from './content/deadfolk_text.png';
import './Navbar.css';

const Navbar = () =>
{
    return (
        <nav className="App-navbar">
            <Link to="/">
                <img src={logoText} className="logo-text" alt="logo-text" />
            </Link>
            <div className='flex-space'/>
            <div className="links">
                <Link to="/">HOME</Link>
                <Link to="/games">GAMES</Link>
                <Link to="/about">ABOUT</Link>
            </div>
        </nav>
    );
}

export default Navbar;