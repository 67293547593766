import Instagram from './content/brands/Instagram_Glyph_White.png';
import Twitter from './content/brands/Twitter social icons - circle - white.png';
import GitHub from './content/brands/github-mark-white.png';

const Footer = () =>
{
    return (
        <div className="App-footer">
            <div className="App-socials">
                <a href="https://www.instagram.com/dead.folk/" target="_blank"><img src={Instagram} className="App-socials-icon" alt="Instagram" /></a>
                <a href="https://www.twitter.com/dead_folk" target="_blank"><img src={Twitter} className="App-socials-icon" alt="Twitter" /></a>
                <a href="https://www.github.com/dead-folk/" target="_blank"><img src={GitHub} className="App-socials-icon" alt="GitHub" /></a>
            </div>
            <p className="App-copyright">&copy; 2023 Deadfolk LLC</p>
        </div>
    );
}

export default Footer;